import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React from 'react';
import './BootstrapNav.css'
import ell from '../../images/ell.png'

function BootstrapNav() {
  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className='navbar' fixed='top' variant='dark'>
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={ell} alt='' style={{width: "150px",height: "65px", objectFit : "cover"}}/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/appointment">Book Now</Nav.Link>
                  <NavDropdown
                    title="Pre-Post LaserCare"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="/prelaser">Pre-Laser Care</NavDropdown.Item>
                    <NavDropdown.Item href="/postlaser">
                      Post-Laser Care
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/contactus">
                      Contact Us
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                
                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form> */}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <Navbar.Brand href="/appointment" className='navbar_Appointment'>Book Appointment</Navbar.Brand>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default BootstrapNav;