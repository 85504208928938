import React, { Suspense } from 'react';
import './App.css';
// import Homepage from './Components/Homepage/Homepage';
import Footer from './Components/Footer/Footer';
import {BrowserRouter, Switch, Route} from "react-router-dom"
// import Booking from './Pages/Booking/Booking';
// import Admin from './Pages/Booking/Admin/admin';
// import Navbar from './Components/Navbar/Navbar'
// import MobileNavigation from './Components/MobileNavigation/MobileNavigation';
import Login from './Pages/Booking/Login/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute'
import { AuthProvider } from "./Components/Context/AuthContext"
import ContactUs from './Pages/Contactus/ContactUs';
import PostLaserCare from './Pages/PrePostLaserCare/PostLaserCare/PostLaserCare';
import PreLaserCare from './Pages/PrePostLaserCare/PreLaserCare/PreLaserCare';
import BootstrapNav from './Components/BootstrapNav/BootstrapNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from './Components/Spinner/Spinner';
import { lazy } from 'react';
const Homepage = lazy(() => import('./Components/Homepage/Homepage'));
const Booking = lazy(() => import('./Pages/Booking/Booking'));
const Admin = lazy(() => import('./Pages/Booking/Admin/admin'));

function App() {
  
  return (
    <div className="App">
      
        <BrowserRouter>
        {/* <Navbar /> */}
         <BootstrapNav />
         <AuthProvider>
          <Switch >
              <Route exact path='/'>
                <Suspense fallback={<Spinner/>}>
                  <Homepage/>
                </Suspense>  
              </Route>
            <Route exact path='/appointment'>
              <Suspense fallback={<Spinner/>}>
                <Booking/>
              </Suspense>
            </Route>
            <ProtectedRoute exact path="/admin" component={Admin} />
            <Route exact path='/login'> <Login/></Route>
            <Route exact path='/contactus'> <ContactUs/></Route>
            <Route exact path='/postlaser'> <PostLaserCare/></Route>
            <Route exact path='/prelaser'> <PreLaserCare/></Route>
          </Switch>
          </AuthProvider> 
        </BrowserRouter>
      <Footer/>
    </div>
  );
}

export default App;
