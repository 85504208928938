import React from 'react'
import Laser from '../../../images/laser.jpg'
import './PostLaserCare.css'

const PostLaserCare = () => {
  return (
    <div className='postlaserContainer'>
        <div className='postlaserContainer_text'>
            <h1>POST-LASER CARE</h1>
        <ul>
            <li><p>Only shaving is allowed for hair removal purposes while treatments are in progress.</p> </li>
            <li><p>Avoid direct sun exposure, hot showers, excessive sweating 24 hours post treatment.</p> </li>
            <li><p>Ensure daily application of minimum SPF 30 follwoing laser treatments.</p> </li>
            <li><p>Pro tip : to accelerate the hair shredding process, exfoliate treated areas 2-3 post laser.</p> </li>
           
        </ul>
        </div>
        <div className='image-sectionpost'>
            <img src={Laser} alt='heroimg'></img>
      </div>
    </div>
  )
}

export default PostLaserCare
