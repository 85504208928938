import React from 'react'
import Laser from '../../images/laser.jpg'
import './ContactUs.css'

const ContactUs = () => {
  return (
    <div className='contactusContainer'>
        <div className='contactusContainer_left'>
            <h1>Contact Us</h1>
           <div className='data-container'><div className='image-sectiontwo'>
            <img src={Laser} alt='heroimg'></img>
            </div>
            <div className='text-container'><h3>Call or text at (778)-855-7263</h3>
            <h3>or email us at euphorialaserlounge@gmail.com</h3>
            <h3>or submit enquiries below</h3>
            <form>
                <input type="text" placeholder='name'></input><br></br>
                <input type="email" placeholder='email'></input><br></br>
                <textarea placeholder='questions or comments'></textarea><br></br>
                <div className='button'><input type="submit" value="SUBMIT"></input></div>
            </form>
            <h3>Visit our Instagram Page @euphorialaer for all the latest updates & more information</h3></div> </div>
      </div>
      
    </div>
  )
}

export default ContactUs
