import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='beauty-para'>
            <h3>EUPHORIA LASER LOUNGE</h3>
            <p>All Right Reserved</p>
        </div>
        <div className='hours-Of-Operation'>
            <h3>Hours of Operation</h3>
            <p>Tuesday to Sunday 10 am - 6 pm</p>
            <p>Monday Closed</p>
        </div>
        <div className='contact-info'>
            <h3>Contact Info</h3>
            <p>778-855-7263</p>
            <p>euphorialaserlounge@gmail.com</p>
        </div>      
    </div>
  )
}

export default Footer