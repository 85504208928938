import React from 'react'
import Laser from '../../../images/laser.jpg'
import './PreLaserCare.css'

const PreLaserCare = () => {
  return (
    <div className='prelaserContainer'>
        <div className='prelaserContainer_text'>
            <h1>PRE-LASER CARE</h1>
        <ul>
            <li><p>12-24 hours prior to appointment, areas that need to be treated must be shaved. Extra charges apply for shaving.</p> </li>
            <li><p>Strictly no waxing, tweezing, plucking, or other hair removal methods aside shaving while getting treatments. Treatment area(s) should not be waxed, tweezed, plucked, bleached 3 weeks prior to inital appointment.</p> </li>
            <li><p>UV exposure is to be avoided at at least 10 days prior to appointment. Tanned or sunburnt skin must wait minimum of 2 weeks before receiving treatment.</p> </li>
            <li><p>Must not be pregnant or breastfeeding.</p> </li>
            <li><p>Must not be on Accutane for at least 6 months before treatment.</p> </li>
            <li><p>Any abnormal skin disorders must be medically cleared by a physician before proceeding with treatments</p> </li>
            <li><p>Please halt use of retinol/retinoid, hydroquinone, AHA/BHA products 3-4 days prior to treatment</p> </li>
        </ul>
        </div>
        <div className='image-sectionpre'>
            <img src={Laser} alt='heroimg'></img>
      </div>
    </div>
  )
}

export default PreLaserCare
