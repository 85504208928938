import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC254j6sXe7CuQdbh2jN5wBS_lyt6vJ-1Q",
  authDomain: "laser-project-46848.firebaseapp.com",
  projectId: "laser-project-46848",
  storageBucket: "laser-project-46848.appspot.com",
  messagingSenderId: "15535122440",
  appId: "1:15535122440:web:0a1055613706b38b31c9bd"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export {db, auth}